import { useEffect } from 'react'

export const useApple = ({ onSuccess }) => {
  useEffect(() => {
    // Load the Apple Login SDK dynamically
    const script = document.createElement('script')
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    script.async = true
    script.onload = () => {
      window.AppleID.auth.init({
        clientId: 'com.howdy.auth', // Your Services ID
        scope: 'name email', // Requested user data
        redirectURI: window.location.origin + '/auth',
        state: 'state', // Optional: a random string to prevent CSRF
        usePopup: true, // Optional: pop-up style sign-in
      })
    }
    document.body.appendChild(script)
  }, [])

  // Callback function for Apple login
  const handleAppleLogin = (type) => {
    window.AppleID.auth
      .signIn()
      .then(({ authorization }) => {
        return fetch(type === 'signup' ? '/partners/register' : '/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            appleId: authorization.code,
          }),
        }).then((response) => response.json())
      })
      .then((data) => {
        onSuccess(data)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  return handleAppleLogin
}
